export default function DesktopNav() {
    return (
        <div className='py-8 w-full flex flex-row justify-center invisible md:visible absolute top-0 left-0 z-30'>
            <a className='px-6 text-tag 2xl:text-2xl' href="#home">
              Home
            </a>
            <a className='px-6 text-tag 2xl:text-2xl' href="#whyUs">
              Why Us?
            </a>
            <a className='px-6 text-tag 2xl:text-2xl' href="#services">
              Services
            </a>
            <a className='px-6 text-tag 2xl:text-2xl' href="#contact">
              Contact us
            </a>
        </div>
    );
}