export default function LandingPageText() {
    return (
        <div className='text-center px-10 py-20'>
            <p className='font-bold inline px-2 underline text-para'>
                Ready to launch your digital dreams? 
            </p>
            <p className='inline text-para'>
                At ARA Coding Services, <br/>
                we&apos;ve got the skills to make it happen. Hit that button and let&apos;s blast off together! <br/>
                – your projects are about to reach new heights! <br/>
            </p>
        </div>
    );
}