export default function ServicePanel(props) {
    return (
        <div className='w-[400px] p-6'>
            <div className={`${props.gradient} bg-gradient-to-b to-transparent p-0.5 rounded-xl`}>
              <div className='bg-service rounded-xl p-6'>
                <img src={props.icon} className='mr-6' alt='pink triangle'/>
                <h3 className='py-4'>{props.title}</h3>
                <p className='text-tag font-light'>
                  {props.text}
                </p>
              </div>
            </div>
        </div>
    );
}