import { useState } from "react";

export default function MobileNav() {

    const [navDisplay, setNavDisplay] = useState(false);
    const showNav = () => setNavDisplay(true);
    const hideNav = () => setNavDisplay(false);

    return(
        <>
            <div className={navDisplay ? "transition-all duration-500 visible fixed top-0 right-0 h-screen w-screen bg-gradient-to-l from-black to-transparent backdrop-blur-md z-10 flex flex-col p-6 items-center" : "hidden opacity-0"}>
            <a className='w-full text-right text-6xl pb-2' onClick={() => hideNav()}>
            X
            </a>
            <div className='bg-white w-2/3 h-1 my-4'/>
            <a className='text-5xl' href="#home">
            Home
            </a>
            <div className='bg-white w-2/3 h-1 my-4'/>
            <a className='text-5xl' href="#whyUs">
            Why us?
            </a>
            <div className='bg-white w-2/3 h-1 my-4'/>
            <a className='text-5xl' href="#services">
            Services
            </a>
            <div className='bg-white w-2/3 h-1 my-4'/>
            <a className='text-5xl' href="#contact">
            Contact us
            </a>
            <div className='bg-white w-2/3 h-1 my-4'/>
            </div>
            <div className='flex flex-row visible items-center p-6 md:invisible absolute top-0 right-0 z-20'>
                <button onClick={() => showNav()}>
                    <img src='/hamburger.svg' />
                </button>
            </div>  
        </>  
    );
}