import ServicePanel from "./ServicePanel";

export default function Services() {
    return (
        <div className='flex flex-row justify-center flex-wrap px-40 max-w-screen-2xl'>
            <ServicePanel
                title="Web Development"
                text="Precision-crafted websites to elevate your online presence and drive engagement."
                gradient="from-fuchsia-600"
                icon="/triangle.svg"
            />
            <ServicePanel
                title="Software Development"
                text="Tailored software solutions designed to streamline your operations and fuel growth."
                gradient="from-indigo-600"
                icon="/square.svg"
            />
            <ServicePanel
                title="Tool Development"
                text="Custom tools engineered to boost productivity and simplify complex workflows."
                gradient="from-rose-600"
                icon="/pentagon.svg"
            />
            <ServicePanel
                title="Scripting & Botting"
                text="Automated solutions and intelligent bots to optimize processes and save time."
                gradient="from-cyan-600"
                icon="/hexagon.svg"
            />
            <ServicePanel
                title="Custom Work"
                text="Unique solutions meticulously crafted to meet your specific needs and exceed expectations."
                gradient="from-yellow-600"
                icon="/septagon.svg"
            />
        </div>
        
    );
}