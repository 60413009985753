export default function IconParagraph(props) {
    return (
        <div className='w-[500px] px-20 p-6'>
            <div className='flex flex-row items-center pb-2'>
                <img src={props.icon} className='inline mr-6' alt='shield icon'/>
                <h3 className='inline'>{props.title}</h3>
            </div>   
            <p className='text-tag font-light'>
                {props.children}
            </p>
        </div>
    );
}