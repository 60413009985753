import Head from "./components/Head";
import Body from './components/Body';

export default function Home() {
  return (
    <main className="relative w-screen text-white overflow-hidden flex flex-col">
      <Head />
      <Body />
    </main>
  );
}
 