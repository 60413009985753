export default function ContactUs() {
    return (
        <div className='text-center' id="contact">
            <h3 className='bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text text-transparent p-6'>Ready to launch your next project into orbit?</h3>
            <h3>Give us a shout, and let&apos;s explore the cosmos of possibilities together!</h3>
            <div className="flex flex-row w-full justify-center m-4">
                <img className="m-4" src="./discord.svg" alt="" />
                <img className="m-4" src="./telegram.svg" alt="" />
            </div>
        </div>
    );
}