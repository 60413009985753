import LandingPage from "./LandingPage";
import SubtitleRule from "./SubtitleRule";
import WhyUs from "./WhyUs";
import ExpertiseSubtitle from "./ExpertiseSubtitle";
import Services from "./Services";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Body() {
    return(
        <div className='md:backdrop-blur-[20px] backdrop-blur-[200px]  flex flex-col items-center w-screen relative overflow-visible'>
            <LandingPage />  
            <SubtitleRule text="Why choose us? "/>
            <WhyUs />
            <ExpertiseSubtitle />
            <Services />
            <SubtitleRule text="Beam us a message "/>
            <ContactUs />
            <Footer />
        </div>
    );
}