import IconParagraph from "./IconParagraph";

export default function WhyUs() {
    return (
        <div className='flex flex-row justify-center flex-wrap pt-20'>
            <IconParagraph title="Security" icon="/security.svg">
                At ARA Coding Services, your data&apos;s security is paramount.
                We utilize cutting-edge encryption and robust security measures
                to safeguard your projects from any potential threats.
            </IconParagraph>
            <IconParagraph title="Anonymous" icon="/anon.svg">
                We offer anonymous coding services, ensuring your privacy and 
                confidentiality throughout the development process. Your identity 
                remains protected, allowing you to focus solely on your project&apos;s success.
            </IconParagraph>
            <IconParagraph title="Private" icon="/private.svg">
                Your projects are treated with the utmost confidentiality at ARA Coding Services.
                We provide a private coding environment, ensuring that your ideas and intellectual
                property remain secure and undisclosed to anyone outside of our team.
            </IconParagraph>
        </div>
    );
}