import DesktopNav from './DesktopNav';
import Background from './Background';
import MobileNav from './MobileNav';
import BackgroundFilter from './BackgroundFilter';

export default function Head() {
    return (
        <>
            <MobileNav />
            <DesktopNav />
            <Background />
            <BackgroundFilter />
        </>
    );
}