import Button from "./Button";
import LandingPageText from "./LandingPageText";

export default function LandingPage() {
    return (
        <div className='w-full text-center flex flex-col justify-between items-center px-10 bg-[url(./stars.svg)] bg-no-repeat bg-cover min-h-screen h-auto pt-16'>
            <div></div>
            <div className="flex flex-col justify-center items-center h-full">
                <img src="/aracoding.svg" alt='ara coding logo' className='p-6'/>
                <div className="">
                    <div className='w-full flex flex-row justify-center items-center'>
                    <h1 className='flex flex-row flex-wrap justify-center'>
                        <div className='relative items-center flex flex-col px-6'>
                        <img src="/aratextbox.svg" className='absolute top-0' alt='box around logo'/>
                        <h1 className='inline bg-gradient-to-r from-rose-600 to-indigo-600 bg-clip-text text-transparent font-extrabold'>ARA</h1>
                        </div>   
                        Coding Services</h1>  
                    </div>  
                    <h2 className='text-tag py-2'>Explore the Stars of Innovation with Code Crafted to Perfection</h2>
                </div>
                <Button />
                <div className='flex flex-row items-center'>
                    <img src="/github.svg" height={30} alt='github logo' className="px-2"/>
                    <h4>GitHub</h4>
                </div>
            </div>
            
            <LandingPageText />
        </div>
        
    );
}