export default function Footer() {
    return (
        <div className='pt-40 py-8 w-full flex flex-row justify-center'>
            <a className='px-6 text-tag'>
                Terms & Conditions
            </a>
            <a className='px-6 text-tag'>
                Privacy
            </a>
            <a className='px-6 text-tag'>
                Copyright © ARA Coding Services, 2024
            </a>
        </div>
    );
}