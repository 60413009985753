export default function Button() {
    return (
        <button className='relative my-4 bg-gradient-to-r from-indigo-600 to-fuchsia-600 rounded-full flex-col items-center'>
            <div className='absolute h-full w-full bg-white px-6 py-4 opacity-0 hover:opacity-100 transition-all rounded-full'>
                <h3 className='h-full w-full bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text text-transparent'>Explore Cosmic Code</h3>
            </div>
            <div className='h-full w-full px-6 py-4 bg-gradient-to-r from-indigo-600 to-fuchsia-600 opacity-100 hover:opacity-0 transition-all rounded-full'>
                <h3 className='h-full w-full'>Explore Cosmic Code</h3>
            </div>
        </button>
    );
    
}