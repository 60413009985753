export default function ExpertiseSubtitle() {
    return (
        <div className='flex flex-col items-center pt-40 pb-20 text-center' id='services'>
            <div>
                <h5 className='inline'>Our </h5>
                <h5 className='inline bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text text-transparent'>Expertise</h5>
            </div>
            <div className='bg-tag w-32 h-1 m-2'/>
            <p className='text-tag'>
            Crafting Solutions with Precision and Passion
            </p>
        </div>
    );
}